// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/messaging";

class Firebase {
  constructor() { 
    const firebaseConfig = {
      apiKey: "AIzaSyDpsj6LAIHhGJo3Wo3aSnJUuo-ELeUhG9E",
      authDomain: "fir-project-25f3c.firebaseapp.com",
      projectId: "fir-project-25f3c",
      storageBucket: "fir-project-25f3c.appspot.com",
      messagingSenderId: "1096638966120",
      appId: "1:1096638966120:web:41846b13737de348fa0edd",
      measurementId: "G-MV5GWF1BZB",
    };

    firebase.initializeApp(firebaseConfig);
  }

  getToken = async () => {
    const messaging = firebase?.messaging();

    return await messaging?.getToken();
  };

  onMessageListener = () => {
    return new Promise((resolve) => {
      const messaging = firebase?.messaging();
      messaging?.onMessage((payload) => {
        resolve(payload);
      });
    });
  };
}

let _firebaseInstance = null;

export const getFirebase = () => {
  if (!_firebaseInstance) {
    _firebaseInstance = new Firebase();

    return _firebaseInstance;
  }

  return _firebaseInstance;
};



